import store from '@/store'
import { updateProjectDetails } from '@/services/projects'
import { successModal, errorModal } from '@/modalMessages'

export async function submit (projectId, data) {
  try {
    const payload = { ...data }
    if (payload.city?.id) payload.city = payload.city.id
    if (payload.street?.id) payload.street = payload.street.text
    const response = await updateProjectDetails(projectId, payload)
    await store.dispatch('properties/loadProject', projectId)
    successModal('De projectgegevens zijn aangepast')
    return response
  } catch (error) {
    errorModal('Fout bij opslaan van projectinstellingen, probeer het opnieuw.')
    throw error
  }
}
