import store from '@/store'
import { getProjectArchitects, getProjectPromoters } from '@/services/projects'
import { submit } from './projectActions'
import { errorModal } from '@/modalMessages'
import { NUMBER_INPUT_POSITIVE, SELECT_NICHE } from '@/forms/schemas/generators'

let promoters = []
let architects = []

const schema = [
  { ...SELECT_NICHE },
  {
    type: 'select',
    name: 'promoter',
    label: 'Promotor',
    placeholder: 'Selecteer promotor',
    get options () {
      return promoters
    }
  },
  {
    type: 'select',
    name: 'architect',
    label: 'Architect',
    placeholder: 'Selecteer architect',
    get options () {
      return architects
    }
  },
  {
    component: 'div',
    class: 'tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4',
    children: [
      {
        name: 'number_of_entities',
        label: 'Aantal units',
        placeholder: 'Aantal units',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'percentage_sold',
        label: 'Aantal verkocht in %',
        placeholder: 'Aantal verkocht in %',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      }
    ]
  },
  {
    type: 'select',
    name: 'state',
    label: 'Status',
    placeholder: 'Selecteer regio',
    options: {
      1: 'Start der werken',
      2: 'Ruwbouw in opbouw',
      3: 'Einde ruwbouwwerken',
      4: 'Afwerking appartementen',
      5: 'Oplevering',
      6: 'Voorverkoop'
    }
  },
  {
    type: 'select',
    name: 'fase',
    label: 'Actieve fase',
    placeholder: 'Selecteer actieve fase',
    options: {
      0: 'Niet van toepassing',
      1: 'Fase 1',
      2: 'Fase 2',
      3: 'Fase 3',
      4: 'Fase 4'
    }
  },
  {
    type: 'translatable',
    name: 'delivery_date',
    label: 'Opleverdatum',
    outerClass: 'tw-w-1/2'
  },
  {
    type: 'translatable',
    name: 'price_min_max_description',
    label: 'Minimumprijs - maximumprijs',
    outerClass: 'tw-w-1/2 tw-mt-4'
  },
  {
    type: 'translatable',
    name: 'bedrooms_description',
    label: 'Slaapkamers',
    outerClass: 'tw-w-1/2 tw-mt-4'
  },
  {
    type: 'translatable',
    name: 'surface_min_max_description',
    label: 'Minimumoppervlakte - maximumoppervlakte (bruto)',
    outerClass: 'tw-w-1/2 tw-mt-4'
  },
  {
    type: 'translatable',
    name: 'parking_car_pitch_description',
    label: 'Parking/staanplaats',
    outerClass: 'tw-w-1/2 tw-mt-4'
  },
  {
    type: 'translatable',
    name: 'terrace_garden_description',
    label: 'Terras/tuin',
    outerClass: 'tw-w-1/2 tw-mt-4'
  }
]

const actions = {
  async load (projectId) {
    try {
      // Project data is already loaded by the layout, no need to call API again.
      const project = store.getters['properties/getProjectById'](projectId)
      const { logo, niche, architect, promoter, ...payload } = { ...project }
      const [architectsResponse, promotersResponse] = await Promise.all([getProjectArchitects(), getProjectPromoters()])

      const promotersResults = promotersResponse.data?.results || []
      const promoterOptions = promotersResults.map(
        ({ id, name }) => {
          return { value: id, label: name }
        })

      if (promoter) {
        promoters = [
          { value: 0, label: '-' },
          ...promoterOptions
        ]
      } else {
        promoters = promoterOptions
      }

      const architectsResults = architectsResponse.data?.results || []
      const architectOptions = architectsResults.map(
        ({ id, name }) => {
          return { value: id, label: name }
        })

      if (architect) {
        architects = [
          { value: 0, label: '-' },
          ...architectOptions
        ]
      } else {
        architects = architectOptions
      }

      // We don't update logo in this form, hence not including it below.
      return { promoter: promoter?.id, architect: architect?.id, niche: niche?.id, ...payload }
    } catch (error) {
      errorModal('Fout bij het laden van projectdetails, probeer het opnieuw.')
      throw error
    }
  },
  submit
}

export default {
  heading: 'Projectinstellingen',
  schema,
  actions
}
